html,
body {
  height: 100%;
  overflow-x: hidden;
}

body {
  position: relative;
}
/* body {
  overflow-x: hidden;
} */

@font-face {
  font-family: "fm";
  src: local("fm"),
    url("./Assets/fonts/Futura/FuturaPTMedium.otf") format("truetype");
}
@font-face {
  font-family: "fb";
  src: local("fb"),
    url("./Assets/fonts/Futura/FuturaPTBold.otf") format("truetype");
}
@font-face {
  font-family: "FTPD";
  src: local("FTPD"),
    url("./Assets/fonts/Futura/FuturaPTDemi.otf") format("truetype");
}
@font-face {
  font-family: "fl";
  src: local("fl"),
    url("./Assets/fonts/Futura/FuturaPTLight.otf") format("truetype");
}

@font-face {
  font-family: "RDSB";
  src: local("RDSB"),
    url("./Assets/fonts/RedHat/RedHatDisplay-SemiBold.otf") format("truetype");
}

@font-face {
  font-family: "RDB";
  src: local("RDB"),
    url("./Assets/fonts/RedHat/RedHatDisplay-Bold.otf") format("truetype");
}

@font-face {
  font-family: "RDM";
  src: local("RDM"),
    url("./Assets/fonts/RedHat/RedHatDisplay-Medium.otf") format("truetype");
}

@font-face {
  font-family: "RDEB";
  src: local("RDEB"),
    url("./Assets/fonts/RedHat/RedHatDisplay-ExtraBold.otf") format("truetype");
}

@font-face {
  font-family: "SVEB";
  src: local("SVEB"),
    url("./Assets/fonts/Supreme/Supreme-Extrabold.otf") format("truetype");
}

@font-face {
  font-family: "SVB";
  src: local("SVB"),
    url("./Assets/fonts/Supreme/Supreme-Bold.otf") format("truetype");
}

@font-face {
  font-family: "SVM";
  src: local("SVM"),
    url("./Assets/fonts/Supreme/Supreme-Medium.otf") format("truetype");
}

@font-face {
  font-family: "SVR";
  src: local("SVR"),
    url("./Assets/fonts/Supreme/Supreme-Regular.otf") format("truetype");
}

/* Landing Nav Bar */
.div_overflow {
  overflow: hidden;
}

.cendrol_log {
  width: 111px;
  height: 39.22px;
}

.nav_mail_icons {
  color: #fdc315;
}
.nav_whatapp {
  color: #00e676;
}

.land_nav_ul li a {
  display: flex;
  gap: 10px;
  text-decoration: none;
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.land_nav_ul > li + li {
  border-left: solid 1px #0a0a0a;
}

.btn_get_free_quote {
  background: linear-gradient(92.68deg, #ffe185 -18.4%, #f7ba00 113.86%);
  border-radius: 5px;
  font-family: "FTPD";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}

.btn_get_free_quote:focus {
  box-shadow: none;
}
/* NAVBAR COMPLETE CSS */
.navbar-brand img {
  width: 150px;
}
ul.topInfo > li {
  font-size: 20px;
  line-height: 1;
  padding: 0px 25px;
  display: inline-block;
}
ul.topInfo > li > a {
  color: #000;
  display: block;
}
ul.topInfo > li > a > i {
  color: #fdc315;
  margin-right: 5px;
}
.siteHeader .navbar {
  padding: 0px;
  left: 0px;
  width: 100%;
  top: 0px;
  z-index: 1000;
  position: absolute;
  transition: ease-in-out 0.5s;
}
.siteHeaderInner .navbar {
  background: #fff;
  position: initial;
  top: 0px;
}
.siteHeaderInner .navbar ul.navbar-nav li a.nav-link {
  color: #9f9898;
}
.siteHeaderInner .topBar {
  border-bottom: solid 1px #e9e9e9;
}
.siteHeader {
  padding: 25px 0px;
}
.siteHeadernew .sticky-wrapper {
  height: auto !important;
}
.siteHeadernew.siteHeader .navbar {
  position: relative;
}
ul.socialIcons {
  font-size: 20px;
  list-style: none;
}
ul.socialIcons li {
  display: inline-block;
}
ul.socialIcons li a {
  color: #000;
}
ul.topInfo > li > a:hover {
  text-decoration: underline;
}
ul.topInfo > li > a {
  color: #000;
  display: block;
  text-decoration: none;
}
.whatsap_icon {
  color: #33b315;
  font-size: 23px;
}
.mail_icon {
  color: #fdc315;
  margin-right: 5px;
}
.colors {
  background: "black";
  width: "100%";
  height: "20px";
  z-index: 1000;
  cursor: pointer;
}
.colors:hover {
  background-color: red;
}
/*  */
/* navbar */
.img_logo {
  width: 7rem;
}
.cost_cal_btn {
  background: linear-gradient(92.68deg, #ffe185 -18.4%, #f7ba00 113.86%);
  border-radius: 5px;
  border: none;
  padding: 10px;
}
.top_menu_search {
  border: none;
  width: 90%;
  font-style: normal;
  font-weight: 500;
  background-color: #f4f4f4;
  font-family: GM;
  font-size: 12px;
  line-height: 14px;
  color: #757575;
}

.top_menu_search:focus {
  outline: none;
}

.profile_img {
  width: 36px;
  height: 36px;
  /* border: 1.2375px solid #fcc314; */
}

/* first section */
.sliding_comp {
  margin-top: 6%;
  position: relative;
}
.overlap_section {
  width: 100%;
  z-index: 10;
  position: absolute;
  bottom: 145px;
  color: #fff;
}
.overlap_section2 {
  z-index: 10;
  position: absolute;
  margin-left: auto;
  color: #fff;
}
.text_overlap {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 22px;
  font-family: fb;
}
.sectionVideo .containerForm {
  top: 50%;
  left: 50%;
  z-index: 12;
  position: absolute;
  transform: translate(-50%, -50%);
}
.form-control {
  height: 42px;
  font-size: 16px;
  border-radius: 0px;
  color: #333333;
  padding: 3px 0px;
  background-color: transparent !important;
  box-shadow: none !important;
  border-color: #d1d1d1;
  border-width: 0px 0px 1px 0px;
}
.verify_n_proceed {
  background: #fcc314;
  border-radius: 5px;
  border: none;
  padding: 14px;
}
.form-group {
  margin-bottom: 20px;
  position: relative;
}
.first_sec_form_div {
  display: block;
}
.first_sec_form {
  position: absolute;
  width: 30%;
  background: rgb(255, 255, 255);
  top: 70px;
  right: 30px;
  font-family: fm;
  padding: 21px;
  border-radius: 10px;
}

/* People choose */
.why_ppl_choose {
  padding: 70px 70px 60px 70px;
}
.why_ppl_choose_head_txt {
  text-transform: uppercase;
  font-family: "SVEB";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: #000000;
  margin-bottom: 0px;
}
.rowPeoplechoose .contentChoose {
  /* max-width: 300px;
  margin-left: auto;
  margin-right: auto; */
  background: #ffffff;
  border: 1.5px solid #f3f3f3;
  border-radius: 1px;
  padding: 34px;
  cursor: pointer;
}

.rowPeoplechoose .contentChoose:hover {
  background: #fffaec;
  box-shadow: 0px 12px 31px -12px rgba(0, 0, 0, 0.1);
  border: none;
}

.why_ppl_choose_row {
  padding-top: 20px;
}

.rowPeoplechoose .iconPeople {
  padding-bottom: 22px;
}

.rowPeoplechoose h6 {
  font-family: "RDSB";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}
.rowPeoplechoose {
  text-align: center;
  padding-bottom: 70px;
}
/* .rowPeoplechoose .row + .row [class*="col"] .contentChoose {
  padding-top: 60px;
  margin-top: 40px;
  border-top: solid 1px #e3e1e1;
} */
.sectionComman.sectionPeople {
  padding: 70px 0px;
}
.sectionCommanInner.sectionHappyCustomerInner {
  padding: 70px 0px;
}
/* strip */
.bgYellowDesign {
  background-color: #fdc315;
  padding: 43px 0px;
}
.btn:hover {
  background-color: #000 !important;
  color: #fff !important;
  /* transform: scale(1.06); */
  box-shadow: 0px 0px 30px rgb(0 0 0 / 20%);
}
.btn,
input[type="submit"] {
  transition: ease-in-out 0.4s;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.11);
}
.btnWhite,
.btndesign_start,
.btn_free_estimate {
  padding: 16px;
  border-radius: 1px;
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  text-decoration: none;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  transform: scale(1);
  transition: ease-in-out 0.4s;
}

.btnWhite,
.btndesign_start {
  background: #ffffff;
}

.btn_free_estimate {
  background: #fdc315;
}

.btnWhite:hover,
.btn_free_estimate:hover {
  background: #000000;
  color: #ffffff;
  /* transform: scale(1.06); */
}

.btndesign_start:hover {
  background: #fdc315;
  /* transform: scale(1.06); */
}

.btnWhite:hover svg path,
.btn_free_estimate:hover svg path {
  fill: #ffffff;
}

.designBuilding h4 {
  margin: 0px;
  font-family: "SVEB";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 33px;
  color: #000000;
}

.designBuilding_sub_txt {
  font-family: "RDM";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  align-items: center;
  color: #0e0e0e;
  padding-top: 8px;
}

.want_to_start_col {
  display: flex;
  justify-content: start;
}

.get_free_col {
  display: flex;
  justify-content: end;
}

.faq_txt {
  text-align: center;
}

/* projects section */
.boxLoadMore {
  padding-top: 40px;
}
a {
  transition: ease-in-out 0.4s;
}
.heading-center {
  text-align: center;
}
h2 {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-family: "SVEB";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: #0a0a0a;
}

.recent_works {
  font-family: "SVEB";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: #000000;
  margin-bottom: 0px;
}

.recent_works_sub_txt {
  font-family: "RDM";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #969595;
  padding-top: 8px;
}

.btn,
input[type="submit"] {
  border-radius: 0px;
  padding: 11px 19px;
  border: none;
  cursor: pointer;
  transform: scale(1);
  transition: ease-in-out 0.4s;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 11%);
  background: linear-gradient(91.79deg, #ffe185 -0.46%, #fcc200 104.3%);
  border-radius: 2px;
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #0a0a0a;
}
.contentHomeDesign:hover {
  transition: 0.5s ease-in-out;
  /* transform: scale(1.18); */
}
.contentHomeDesign .imgDesign img {
  width: 100%;
  height: 171px;
  object-fit: cover;
  border-radius: 2px;
}
.contentHomeDesign .imgDesign {
  margin-bottom: 20px;
}
.sub-heading {
  font-family: "RDM";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #919191;
  padding-top: 8px;
}
h4 {
  margin-bottom: 12px;
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.sizeDetail .row [class*="col"]:first-child {
  padding-right: 5px;
}
.sizeDetail .row [class*="col"] + [class*="col"] {
  border-left: solid 1px #e3e1e1;
  padding-left: 15px;
  padding-right: 10px;
}
.sizeDetail .btn {
  width: 100%;
  min-width: inherit;
  height: 36px;
  padding: 10px;
  box-shadow: none !important;
  background: linear-gradient(99.98deg, #646464 4.09%, #0e0e0e 100.77%);
  border-radius: 2px;
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: #ffffff;
}

.sizeDetail .btn:hover {
  background: #fdc315 !important;
  transform: none;
  box-shadow: none !important;
  transition: none;
  color: #0e0e0e !important;
}
.contentHomeDesign h5 {
  margin-bottom: 0px;
  font-family: "RDEB";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}
.contentHomeDesign h6 {
  margin-bottom: 2px;
  font-family: "RDSB";
  font-size: 12px;
  line-height: 16px;
  color: #939090;
}
.sizeDetail .row + .row {
  margin-top: 9px;
}
.contentHomeDesign .designDetail {
  padding: 18px;
  background-color: #ffffff;
}
.btnLoadMore {
  font-family: fb;
  text-align: center;
  padding: 50px 0px 0px 0px;
}
.boxLoadMore > .row + .row {
  margin-top: 40px;
}
.arows_ {
  margin-left: 5px;
}

/* package */
.accordion-header {
  cursor: pointer;
  align-items: center;
}
.sub-heading1 {
  font-family: "RDM";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #969595;
}
.sub-heading2 {
  font-size: 13px;
  color: #969595;
  margin-bottom: 0px;
  font-family: fm;
}
.package_city {
  color: #fdc315;
}
.package_inner_divs {
  text-align: left;
  padding: 18px;
  background: #fafafa;
  border-radius: 6px;
}
.pack_first_card {
  /* border-bottom: 1px solid #e2e2e2; */
  /* padding-top: 10px; */
  display: flex;
  align-items: center;
  padding: 6px;
  padding-bottom: 16px;
}
.collapse_strips {
  padding: 10px 0px;
  border-bottom: none;
}
.accordion-item {
  background-color: #fafafa;
  border: none;
}
.pack_headings {
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 35px;
  color: #0a0a0a;
}
.package_collapse_icon {
  color: #fdc315;
  cursor: pointer;
}
.design_points {
  margin-top: 10px;
  font-family: "RDB";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.design_points ul li {
  font-family: "RDM";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.first_comp_heading {
  font-family: "RDB";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}
.right_tick {
  color: #3add5e;
  margin-right: 5px;
}
.collapse_strips {
  padding: 10px 0px;
  /* border-bottom: 1px solid lightgray; */
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-top: 1px solid #e2e2e2;
  border-left: 0;
  border-radius: 0;
}
/* Custom designing black */
.customdesign_banner .btn {
  height: 72px;
  font-size: 18px;
  line-height: 72px;
  padding: 0px 40px;
}
.customdesign_banner {
  padding: 47px 0px;
  text-align: center;
  background-color: #000;
}
.customdesign_banner .btn:hover {
  color: #000 !important;
  background-color: #fff !important;
}
.customdesign_banner h3 {
  padding-bottom: 45px;
  font-family: "SVEB";
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
  margin: 0px;
  text-transform: none;
  text-align: center;
}

/* Popup design css */
/* .btn:focus,
input[type="submit"]:focus, */
.btn:hover,
input[type="submit"]:hover {
  background: #000000;
  color: #ffffff;
  /* transform: scale(1.06); */
  box-shadow: 0px 0px 30px rgb(0 0 0 / 20%);
}
.btn:focus,
input[type="submit"]:focus {
  box-shadow: none;
  outline: none;
}
.btn.btn-lg {
  height: 58px;
  line-height: 58px;
  font-size: 16px;
  min-width: inherit;
  padding: 0px 40px;
}
h3 {
  /* color: #333333; */
  font-size: 40px;
  font-weight: bold;
  font-family: fb;
  margin-bottom: 23px;
  text-transform: uppercase;
}
.formDesigner {
  padding: 24px;
  background: #ffffff;
  backdrop-filter: blur(3.75px);
  border-radius: 2px;
}
.modal .formDesigner {
  max-width: 100%;
}
.modal button.close {
  right: 20px;
  opacity: 1;
  position: absolute;
  z-index: 100;
  font-size: 26px;
  font-weight: 300;
  top: 10px;
  outline: none;
}
/* Cost calculattor */
.cal_logo {
  width: 22px;
  margin-right: 5px;
}
.talk-bubble {
  margin: 0px;
  padding: 3px;
  display: inline-block;
  position: absolute;
  width: 117px;
  height: 27px;
  background-color: #49f046;
  border-radius: 3px !important;

  top: 40px;
  text-align: center;
}
.cost_cal_recommended {
  text-shadow: 1px 1px 10px lightslategrey;
  color: #000000;
  font-family: FTPD;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.tri-right.btm-left:after {
  content: " ";
  position: absolute;
  width: 15px;
  height: 15px;
  left: 20px;
  right: auto;
  top: auto;
  bottom: -10px;
  border: 10px solid;
  border-color: transparent transparent transparent #49f046;
  transform: matrix(1, 0, 0, -1, 0, 0);
  border-radius: 1px;
}
.background_all {
  background: #fafafa;
  border-radius: 3.75px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
}
.cost_cal_packages_select {
  border: none;
  padding: 7px 12px;
  font-size: 14px;
  font-family: FTPD;
  background: #ffffff;
  border-radius: 2px;
}
.cal_cost_rate {
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  color: #000000;
}

.spec_name {
  font-family: "SVM";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #3e3e3e;
}
.span_SBUA {
  font-family: "SVM";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
}
.quantity__minus,
.quantity__plus {
  display: block;
  width: 22px;
  height: 23px;
  margin: 0;
  color: #000 !important;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px !important;
  border: 1px solid #000;
  background: #fff;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
}
.cost_cal_input_fields {
  width: 100%;
  background: #fafafa;
  border-radius: 2px;
  border: none;
  padding: 13px;
  outline: none;
  font-family: "SVR";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #969696;
}

.inc_dec_btn {
  background: #fafafa;
  border-radius: 12.8648px;
  font-family: "SVM";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #3e3e3e;
  border: none;
  width: 25px;
  height: 25px;
}

.cal_project_number {
  font-family: "SVM";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #0a0a0a;
}

.cal_calculated_value {
  font-family: "SVM";
  font-style: normal;
  font-weight: 500;
  font-size: 19.5px;
  line-height: 26px;
  color: #0a0a0a;
}

.inc_dec_val {
  font-family: "SVM";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #3e3e3e;
  padding: 6px;
}
.quantity__input {
  width: 32px;
  height: 19px;
  margin: 0;
  padding: 0;
  text-align: center;
  border: none;
  background: #fff;
  color: #8184a1;
}
.quantity__input2 {
  width: 32px;
  height: 19px;
  margin: 0;
  padding: 0;
  text-align: center;
  border: none;
  background: #fff;
  color: #8184a1;
}
.quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.cost_cal_heading {
  font-size: 30px;
  font-family: fb;
}
.modal-header {
  border-bottom: none;
}

.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #e8e8e8;
}

.or_txt {
  font-family: "SVM";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #3e3e3e;
}

.popup_details {
  margin-bottom: 0px !important;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}
.cal_btn {
  background: #fdc315;
  border-radius: 2px;
  border: none;
  padding: 15px 23px;
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  justify-content: center;
}

.emi_txt {
  font-family: "SVM";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.cal_cost_button,
.get_me_specs_button {
  border: none;
  padding: 16px;
  width: 100%;
  border-radius: 2px;
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #0a0a0a;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.cal_cost_button {
  background: #fdc315;
}

.get_me_specs_button {
  background: #f9f9f9;
}

.package_names {
  font-family: "SVM";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #a7a7a7;
}
.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

.total_cost_txt {
  font-family: "SVM";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #0a0a0a;
}

.total_cost_value {
  font-family: "SVEB";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #0a0a0a;
  margin: 0px;
}

/* Get free quote */
.keep_browsing {
  border: none;
  background: #fdc315;
  border-radius: 1px;
  padding: 15px 30px;
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.btn_verify {
  background: #fdc315;
  border-radius: 2px;
  border: none;
  padding: 8px 22px;
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}
.thankyou_heading,
.thankyou_heading_page {
  font-family: "SVEB";
  font-style: normal;
  font-weight: 800;
  text-align: center;
  color: #0a0a0a;
}

.thankyou_heading {
  font-size: 22px;
  line-height: 36px;
}

.thankyou_heading_page {
  font-size: 36px;
  line-height: 48px;
}

.thank_line,
.thanku_line {
  background-color: #fdc315;
  height: 3px !important;
  margin: 12px 0px;
}

.thank_line {
  width: 76%;
}

.thanku_line {
  width: 25%;
}
.cls_btn {
  position: absolute;
  right: 0;
  padding: 10px;
  z-index: 9999;
}
/* packages */

#package2 {
  background: linear-gradient(
    108.13deg,
    #da9917 -14.49%,
    #ffff90 64.66%,
    #ecbf26 101.91%
  );
}
.cost_cal_packages_select:focus {
  outline: none !important;
}

.cost_cal_packages_select:hover {
  background-color: linear-gradient(
    108.13deg,
    #da9917 -14.49%,
    #ffff90 64.66%,
    #ecbf26 101.91%
  ) !important;
}
.cost_cal_packages_select {
  background-color: #f4f4f4;
  border-radius: 2px;
  width: 100% !important;
  border: none !important;
}
/* Present in */
.land_present {
  height: 310px;
  background: #fafafa;
}
.land_present_in_txt {
  color: #000000 !important;
  font-family: "SVEB";
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 35px;
}

.land_cols_present {
  margin-bottom: 1rem !important;
}

.land_present_arrow_btn {
  background: #ffffff;
  border-radius: 1px;
  color: #000000;
  border: none;
  /* width: 42px;
  height: 36px; */
  padding: 5px 13px;
}

.land_arrow_icon {
  font-size: 25px;
}

.land_present_arrow_btn:hover {
  background: #fdc315;
  color: #0a0a0a;
}

.land_present_img {
  background: #ffffff;
  border-radius: 7px;
  text-align: center;
  /* height: 120px; */
}

.land_present_img img {
  max-height: 80px;
  max-width: 150px;
}
/* Customer Feedback */
.land_cust_feed_head_txt {
  text-transform: uppercase;
  font-family: "SVEB";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: #000000;
}
.cust_love_icon {
  font-size: 40px !important;
  color: red;
  vertical-align: bottom !important;
}

.land_cust_feed_sub_txt {
  padding-bottom: 50px;
  font-family: "RDM";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #969595;
  padding-top: 8px;
}

.mainHappyCustomerBox {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  text-align: center;
  /* margin-bottom: 20px; */
  position: relative;
}

.mainHappyCustomerBox .customerImage {
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}

.mainHappyCustomerBox .customerImage img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: solid 3px #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.mainHappyCustomerBox p {
  min-height: 100px;
  margin-bottom: 0px;
  font-family: "RDM";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #969595;
}

.land_cust_feedback {
  padding-top: 30px;
}

.land_cust_line {
  height: 3px;
  width: 20px;
  background-color: #fdc315;
}

.mainHappyCustomerBox h4 {
  font-family: "SVEB";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #0e0e0e;
  margin-bottom: 5px;
}

.mainHappyCustomerBox h5 {
  margin-bottom: 16px;
  font-family: "RDM";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #0e0e0e;
}

.land_feedback_arrow_prev,
.land_feedback_arrow_next {
  position: absolute;
  top: 50%;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.59%;
  padding: 0;
  height: 34.5px;
  text-align: center;
  border: 0;
  /* opacity: 0.5; */
  transition: opacity 0.15s ease;
  background: #000000;
  color: #fff;
  border-radius: 2px;
}

.land_feedback_arrow_prev {
  left: -20px;
}

.land_feedback_arrow_next {
  right: -20px;
}

.land_feedback_arrow_prev:hover,
.land_feedback_arrow_next:hover {
  background: #fdc315;
  color: #0a0a0a;
}

.land_feedback_arrow_prev:hover svg path,
.land_feedback_arrow_next:hover svg path {
  fill: #0a0a0a;
}

/* FAQ */
.land_faq_head_txt {
  font-family: "SVEB";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
}
.land_faq_sub_txt {
  font-family: "RDM";
  font-size: 16px;
  line-height: 21px;
  color: #969595;
  padding-top: 8px;
}

.accordion {
  padding-bottom: 40px;
}
.accordion .card {
  margin-bottom: 16px;
  border: none;
}
.accordion .card-body {
  margin-top: 14px;
  position: relative;
  padding: 15px 70px 15px 15px;
  background: #fafafa;
  border-radius: 1px;
  font-family: "RDM";
  font-size: 14px;
  line-height: 26px;
  color: #919191;
}
.accordion .card-body:after,
.accordion .card-body:before {
  width: 4px;
  height: 20px;
  content: "";
  bottom: 100%;
  position: absolute;
  background-color: #fdc315;
}
.accordion .card-body:after {
  left: 70px;
}
.accordion .card-body:before {
  right: 70px;
}
.accordion .card-header {
  border-radius: 0px !important;
  background-color: transparent;
  border: none;
  padding: 0px;
}
.accordion .card-header button {
  display: block;
  position: relative;
  text-decoration: none;
  padding: 15px 80px 15px 18px;
  background-color: #fdc315;
  width: 100%;
  border: none;
  text-align: start;
  border-radius: 1px;
  font-family: "RDM";
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.accordion .card-header button.collapsed {
  background: #fafafa;
}

.accordion button .icon {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 22px;
  background: #fff;
  right: 13px;
  top: 30%;
  color: #000;
  font-size: 13px;
  border-radius: 50%;
  font-weight: 900;
  text-align: center;
  line-height: 30px;
}

.accordion .collapsed .icon {
  background: #fdc315;
}

.accordion .icon {
  background: #fdc315;
}

.accordion button .icon::before {
  position: absolute;
  content: "";
  top: 11px;
  left: 7px;
  width: 10px;
  height: 2px;
  background: currentColor;
}
.accordion button .icon::after {
  position: absolute;
  content: "";
  top: 7px;
  left: 11px;
  width: 2px;
  height: 10px;
  background: currentColor;
}

.accordion button[aria-expanded="true"] .icon::after {
  width: 0;
}
/* Footer */
.footer {
  color: #666;
  font-size: 18px;
  font-weight: 500;
  background-color: #fff;
}
.rowMainFooter {
  padding: 50px;
}

.main_align {
  padding: 0px 50px;
}
.boxCopyright {
  text-align: center;
  padding: 18px 0px;
  background-color: #ececec;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.boxCopyright p {
  font-family: "RDSB";
}

.footerLogo {
  width: 150px;
  margin-bottom: 30px;
}
.footerLogo img {
  width: 100%;
}
.rowMainFooter h4 {
  text-transform: none;
  padding-bottom: 12px;
  font-family: "SVEB";
  position: relative;
  margin-bottom: 0px;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}
.rowMainFooter h4:after {
  width: 40px;
  height: 4px;
  bottom: 0px;
  left: 0px;
  content: "";
  position: absolute;
  background-color: #fdc315;
}

ul.socialIcons li a:hover {
  color: #fdc315;
}
ul.socialIcons {
  font-size: 20px;
  list-style: none;
  padding-left: 0px;
}
ul.socialIcons li {
  display: inline-block;
}
ul.socialIcons li + li {
  margin-left: 20px;
}
ul.socialIcons li a {
  color: #000;
}

ul.listContactInfo {
  list-style: none;
  padding-left: 0px;
}
ul.listContactInfo li:first-child {
  border-top: none;
}
ul.listContactInfo li {
  position: relative;
  padding-left: 33px;
  padding-top: 18px;
  margin-top: 10px;
  border-top: solid 1px #cbcbcb;
}
ul.listContactInfo li a {
  text-decoration: none;
  font-family: "RDSB";
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}
ul.listContactInfo li a:hover {
  color: #fdc315;
}

.land_li_icons {
  position: absolute;
  left: 0px;
  color: #fdc315;
  top: 23px;
}
.mob_cal_btn {
  display: none;
  background: #fdc315;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  font-family: FTPD;
}
.errors {
  color: red !important;
}
.otp_sent,
.otp_worong,
.errors {
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
}

.otp_sent {
  color: #3add5e;
}

.otp_worong {
  color: red;
}
#signup {
  display: none;
}
.popup_form_btn {
  background: #fdc315;
  border-radius: 2px;
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #221e1f;
  border: none;
  width: 100%;
  padding: 14px;
}
.get_free_quote_btn {
  border: 1px solid #000;
  background-color: #fff;
}
.thank_subheading,
.thank_subheading_page {
  font-family: "RDM";
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: #969696;
}

.thank_subheading {
  font-size: 15px;
}

.thank_subheading_page {
  font-size: 18px;
}

li {
  list-style-image: url(./Assets/img/tik.svg);
}
/* Cost Calculator PAGE*/
#form_if_verified {
  display: block;
}
#form_before_verify {
  display: none;
}
.costcal_comp {
  margin-top: 7%;
  /* background: #f9f9f9; */
  padding-top: 5%;
}
.cost_cal_inner_div {
  background-color: #fff;
  padding: 25px;
  margin-bottom: 40px;
}
.form-control1 {
  font-family: "SVM";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #0a0a0a;
  padding-bottom: 10px;
}

.home_loan_cal_txt {
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

._cost_cal_inputField {
  width: 100%;
  padding: 13px;
  border: none;
  background: #fafafa;
  border-radius: 2px;
  font-family: "SVR";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #969696;
}
.form_OTP_not,
.resend_otp {
  font-family: "SVM";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}

.form_OTP_not {
  color: #969696;
}

.resend_otp {
  color: #fcc314;
  cursor: pointer;
  pointer-events: none;
}
._cost_cal_inputField:focus {
  outline: none;
}
.payment-amount {
  /* visibility: hidden; */
  display: inline-block;
  width: 80px;
}

.home_loan_caldiv {
  background: #fafafa;
  border-radius: 2px;
  padding: 20px;
}
.loan_cost_cal_inputField {
  background-color: #ffffff;
  border-radius: 2px;
  padding: 13px;
  border: none;
  width: 100%;
  font-family: "SVR";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #969696;
}
.loan_cost_cal_inputField:focus {
  outline: none;
}
.loan_final_cal {
  font-family: FTPD;
}
/* disableing the arrow of input fields */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.divider_line {
  border: 1px solid #e8e8e8;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.verified_sucess {
  padding: 10px;
  border: none;
  background: #00e676;
  border-radius: 2px;
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  width: 100%;
}
.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}
.cal_btn1 {
  background: #fdc315;
  border-radius: 2px;
  border: none;
  padding: 11px;
  width: 100%;
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

/* Thank You  */
.thankyou_main {
  margin-top: 7%;
  background: #f9f9f9;
  padding-top: 5%;
  padding-bottom: 5%;
}
#modalForm2 {
  z-index: 999999999;
}
#carouselExampleSlidesOnly1 {
  display: none;
  background: #000;
  color: #fff;
  padding: 10px;
  text-align: center;
}
.sliding_cont h3 {
  font-size: 30px;
  font-weight: bold;
  font-family: fb;
  margin-bottom: 23px;
  text-transform: uppercase;
}
.caro_caption {
  width: 485px;
  right: auto;
  text-align: left;
  left: 25px;
  bottom: 20%;
  color: #fff;

  background-color: rgba(0, 0, 0, 0.7);
  padding: 22px;
  font-family: fb;
}
.rs_symbol {
  font-family: system-ui;
}

.modal_head_txt {
  font-family: "SVEB";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
}

.modal_sub_txt {
  font-family: "RDM";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #919191;
}

.user_form_label {
  padding-bottom: 10px;
  font-family: "SVM";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #0a0a0a;
}

.user_form_fileds {
  background: #f2f2f2 !important;
  border-radius: 2px;
  padding: 15px;
  border: none;
  font-family: "SVR";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #909090;
}

.form-control:focus,
.form-control:hover {
  background: #ffffff !important;
  border: 1px solid #fcc216;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(252, 194, 0, 0.34);
  border-radius: 2px;
}

.cost_cal_head_txt {
  font-family: "SVEB";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: #0a0a0a;
}

.cost_cal_sub_txt {
  font-family: "RDM";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #969595;
}

.disclaimer_head_txt {
  font-family: "SVEB";
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  line-height: 26px;
  color: #000000;
}

.disclaimer_sub_txt {
  font-family: "SVR";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
}

.disclaimer_line {
  border: 1px solid #fdc315;
  width: 10%;
  margin-top: 14px;
}

.package_txt {
  font-family: "SVM";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #0a0a0a;
}

.first_section_background {
  background-image: url("./Assets/img/newcom/Background.png");
}

.first_sec_txt_col {
  align-self: center;
  padding-left: 6%;
}

.first_sec_head_txt {
  font-family: "SVEB";
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 53px;
  color: #000000;
}

.first_sec_sub_txt {
  font-family: "RDM";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  color: #000000;
  border-left: 3px solid #fdc315;
  padding-left: 20px;
}

.scroll_down_icon {
  background: #ffffff;
  border-radius: 16.8727px;
  padding: 8px;
}

.scroll_down_txt {
  font-family: "SVR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.whats_app_icon {
  background: #00e676;
  border-radius: 50%;
  padding: 11px;
  position: fixed;
  top: 88%;
  right: 50px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  text-align: center;
  text-decoration: none;
  z-index: 99 !important;
}

.whats_app_icon:hover {
  border-radius: 27.5px;
}

.chat_with_us_txt {
  font-family: "SVB";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  display: none;
}

.whats_app_icon:hover .chat_with_us_txt {
  display: block;
}

.build_nav {
  background: #fff;
  padding: 1rem 3rem;
}

.mb_hd_txt {
  display: none;
}

.scroll_down {
  display: flex;
  gap: 15px;
  cursor: pointer;
  align-items: center;
  padding-top: 3rem;
}

.faq_txt {
  padding-bottom: 3rem;
}

.recent_works_section {
  margin-bottom: 3rem;
}

.cal_divider {
  border-right: 1px solid #e8e8e8;
}

.first_sec_image {
  width: 100%;
  padding-top: 8.6%;
}

/* @media only screen and (min-width: 320px) {
  .build_nav {
    padding: 20px 0px;
  }

  .designBuilding h4 {
    font-size: 18px !important;
  }

  .first_sec_sub_txt {
    font-size: 14px !important;
  }

  .main_align {
    padding: 0px;
  }
} */

/* For Mobile Devices */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .boxLoadMore {
    padding-top: 0px !important;
  }
  .nt {
    display: none;
  }
  .sub-heading {
    font-size: 14px;
    line-height: 22px;
  }

  .loan_final_cal {
    flex-direction: column-reverse;
  }
  .cal_btn {
    width: 100%;
  }
  #carouselExampleSlidesOnly1 {
    display: block;
  }
  .thankyou_main {
    margin-top: 24%;
  }
  .costcal_comp {
    margin-top: 30%;
    background: #f9f9f9;
  }
  .contentHomeDesign {
    margin-bottom: 40px;
  }
  .sliding_comp {
    margin-top: 20% !important;
  }
  /* Nav Bar */
  .land_nav_ul > li + li {
    border-left: none;
  }

  .land_nav_toggle {
    padding-top: 2rem;
  }

  .land_nav_ul li {
    padding-top: 10px;
  }

  .navbar_li_div {
    padding-bottom: 10px;
  }
  .top_banners_ {
    width: 100%;
  }
  .text_overlap h3 {
    font-size: 26px;
  }
  .overlap_section {
    width: unset !important;
    position: initial;
  }
  .mob_cal_btn {
    display: block;

    position: fixed;
    z-index: 999;
    bottom: 20px;
    /* margin-left: auto; */
    margin-left: 55px;
  }

  .why_ppl_choose {
    padding: 30px 0px 0px 0px;
  }
  .first_sec_form {
    position: initial;
    width: 100%;
  }
  .rowPeoplechoose .row [class*="col"] .contentChoose {
    margin-top: 20px;
  }
  .rowPeoplechoose .contentChoose {
    padding: 20px;
  }
  .rowPeoplechoose {
    padding-bottom: 50px;
  }
  .why_ppl_choose_row {
    padding-top: 0px;
  }
  .sizeDetail .row + .row {
    margin-top: 6px;
  }
  /* .sizeDetail .row [class*="col"] {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 3px 15px;
  } */

  /* Present in */
  .land_present {
    height: auto;
  }

  .land_present_arrow_btn {
    /* padding: 3px 6px; */
    padding: 9px 16px;
  }

  /* Land Feed Back */
  .land_cust_feed_head_txt {
    font-size: 22px;
    line-height: 30px;
  }
  .cust_love_icon {
    font-size: 27px !important;
  }
  .land_cust_feed_sub_txt {
    padding-bottom: 30px;
    line-height: 23px;
  }
  .land_cust_feedback {
    padding-top: 15px;
  }

  .mainHappyCustomerBox {
    padding: 15px !important;
  }

  .mainHappyCustomerBox .customerImage {
    padding-bottom: 15px;
  }
  .mainHappyCustomerBox p {
    line-height: 1.2 !important;
    font-size: 13px;
    min-height: inherit;
  }
  .mainHappyCustomerBox h4 {
    padding-top: 15px;
    font-size: 14px;
    line-height: 19px;
  }

  .mainHappyCustomerBox h5 {
    font-size: 12px;
    line-height: 16px;
  }

  /* FAQ */
  .accordion .card-header button {
    line-height: 19px;
    font-size: 14px;
    padding: 15px 58px 17px 18px;
  }
  .accordion button .icon {
    right: 16px;
  }
  .accordion .card-body {
    padding: 15px 25px 15px 18px;
    font-size: 14px;
    line-height: 26px;
  }

  /* Footer */
  .listContactInfo {
    padding-left: 0px;
  }

  .rowMainFooter {
    padding: 40px 0px;
  }

  .main_align {
    padding: 0px;
  }
  .footer {
    font-size: 15px;
  }
  ul.socialIcons {
    font-size: 18px;
    margin-bottom: 0px;
  }

  ul.socialIcons li + li {
    margin-left: 10px;
  }

  .rowMainFooter [class*="col"] + [class*="col"] {
    margin-top: 30px;
  }

  .customdesign_banner h3 {
    text-align: center;
    font-size: 22px;
  }

  .get_free_col {
    justify-content: center;
    padding-top: 20px;
  }

  /* .sizeDetail .row [class*="col"] + [class*="col"] {
    border-left: none !important;
  } */

  .faq_txt,
  .heading-center,
  .recent_works,
  h2,
  .sub-heading1 {
    text-align: center !important;
  }

  .land_arrow_icon {
    font-size: 20px;
  }

  .first_sec_head_txt {
    font-size: 28px;
    line-height: 38px;
    text-align: center;
  }

  .build_nav {
    padding: 20px 0px;
  }

  .first_sec_sub_txt {
    border-left: 0px;
    padding-left: 0px;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
  }

  .mb_hd_txt {
    display: block;
  }

  .dK_hd_txt {
    display: none;
  }

  .btn_first_sec {
    display: flex;
    justify-content: center;
  }

  .scroll_down {
    display: none;
    padding-top: 0px;
  }

  .land_present_in_txt {
    font-size: 22px;
    line-height: 30px;
  }

  .why_ppl_choose_head_txt {
    font-size: 22px;
    line-height: 30px;
  }

  .rowPeoplechoose h6 {
    font-size: 13px;
    line-height: 17px;
  }

  .iconPeople img {
    width: 26.6%;
  }

  h2 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 8px;
  }

  .sub-heading1 {
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 0px;
  }

  .designBuilding h4 {
    font-size: 18px;
    line-height: 33px;
    text-align: center;
  }

  .spacing_container {
    padding: 0px;
  }

  .recent_works_section {
    margin-bottom: 20px;
  }

  .recent_works {
    font-size: 22px;
    line-height: 30px;
  }

  .recent_works_sub_txt {
    font-size: 15px;
    line-height: 23px;
  }

  h4 {
    font-size: 20.4094px;
    line-height: 28px;
  }

  .contentHomeDesign h6,
  .contentHomeDesign h5 {
    font-size: 13.6063px;
    line-height: 18px;
  }

  .sizeDetail .btn {
    font-size: 13.6063px;
    line-height: 18px;
  }

  .btnLoadMore {
    padding: 0px;
  }

  .package_svg svg {
    width: 41px;
    height: 41px;
  }

  .package_inner_divs {
    padding: 15px;
    border-radius: 3.94366px;
  }

  .pack_first_card {
    padding: 0px 0px 15px 0px;
  }

  .pack_headings {
    font-size: 17px;
    line-height: 23px;
  }

  .first_comp_heading,
  .design_points ul li {
    font-size: 11.831px;
    line-height: 16px;
  }

  .design_points ul li::marker {
    font-size: 20px;
  }

  .package_collapse_icon {
    font-size: 10px;
  }

  .accordion {
    padding-bottom: 30px;
  }

  .faq_txt {
    padding-bottom: 18px;
  }

  .land_faq_head_txt {
    font-size: 21px;
    line-height: 30px;
    text-align: center;
  }

  .land_faq_sub_txt {
    font-size: 15px;
    line-height: 23px;
  }

  .sectionCommanInner.sectionHappyCustomerInner,
  .customdesign_banner,
  .bgYellowDesign {
    padding: 30px 0px;
  }

  .want_to_start_col {
    justify-content: start;
  }

  .footerLogo {
    margin-bottom: 25px;
  }

  .cal_divider {
    border-right: 0px;
  }

  .spec_name {
    font-size: 14px;
    line-height: 18px;
  }

  .cal_cost_rate {
    font-size: 14px;
    line-height: 20px;
  }

  .span_SBUA {
    font-size: 10px;
  }

  .package_names {
    font-size: 12px;
  }

  .get_me_specs_button {
    margin-top: 20px;
    padding: 16px 14px;
    font-size: 12px;
  }

  .thankyou_heading_page {
    font-size: 28px;
    line-height: 40px;
  }

  .thanku_line {
    width: 75%;
  }
  .background_all {
    padding: 5px;
    width: 30px;
  }

  .inc_dec_btn {
    width: 20px;
    height: 20px;
  }

  .car_parking {
    width: 115px;
  }

  .inc_dec_val,
  .inc_dec_btn {
    font-size: 16px;
    line-height: 18px;
  }

  .first_section {
    padding-top: 20%;
  }

  .navbar_container {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .first_sec_image {
    width: 85%;
  }

  .first_img_dev {
    display: flex;
    justify-content: end;
  }

  .designBuilding_sub_txt {
    font-size: 13px;
    line-height: 24px;
    text-align: center;
  }

  .cost_cal_head_txt {
    font-size: 26px;
    line-height: 30px;
  }
}

/* For iPads, Tablets Devices */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .boxLoadMore > .row + .row {
    margin-top: 0px !important;
  }
  .contentHomeDesign {
    margin-bottom: 35px !important;
  }
  .cal_btn {
    width: 100% !important;
  }
  .caro_caption {
    bottom: 20px;
  }
  .btnWhite {
    padding: 10px !important;
    line-height: 30px !important;
  }
  .sliding_comp {
    margin-top: 10% !important;
  }
  /* Nav Bar */
  .land_nav_ul > li + li {
    border-left: none;
  }

  .land_nav_toggle {
    padding-top: 2rem;
  }

  .land_nav_ul li {
    padding-top: 10px;
  }

  .navbar_li_div {
    padding-bottom: 10px;
  }

  /* Present in */
  .land_present {
    height: auto;
  }

  /* Land Feed Back */
  .land_cust_feedback {
    padding-top: 15px;
  }

  .mainHappyCustomerBox {
    padding: 15px !important;
  }

  .mainHappyCustomerBox .customerImage {
    padding-bottom: 15px;
  }
  .mainHappyCustomerBox p {
    line-height: 1.2 !important;
    font-size: 14px;
  }
  .mainHappyCustomerBox h4 {
    padding-top: 15px;
    font-size: 17px;
  }

  /* FAQ */
  .accordion .card-header button {
    line-height: 1.2;
    font-size: 19px;
    padding: 17px 65px 17px 25px;
  }
  .accordion button .icon {
    right: 16px;
  }
  .accordion .card-body {
    padding: 30px 25px 10px 25px;
  }

  .want_to_start_col {
    justify-content: center;
  }

  .build_nav {
    padding: 20px 0px;
  }

  .first_sec_head_txt {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }

  .first_sec_sub_txt {
    border-left: 0px;
    padding-left: 0px;
    text-align: center;
  }

  .btn_first_sec {
    display: flex;
    justify-content: center;
  }

  .scroll_down {
    display: none;
    padding-top: 0px;
  }

  .main_align {
    padding: 0px;
  }

  .why_ppl_choose {
    padding: 40px;
  }

  .contentChoose_tab {
    padding: 24px !important;
  }

  .rowPeoplechoose,
  .bgYellowDesign {
    padding-bottom: 40px;
  }

  .designBuilding h4 {
    font-size: 18px;
    text-align: center;
  }

  .get_free_col {
    justify-content: center;
    padding-top: 20px;
  }

  .sectionCommanInner.sectionHappyCustomerInner {
    padding: 40px 0px;
  }

  .btnLoadMore {
    padding: 0px;
  }

  .rowMainFooter {
    padding: 40px 0px;
  }

  .costcal_comp {
    margin-top: 12%;
    background: #f9f9f9;
  }

  .cal_divider {
    border-right: 0px;
  }

  .designBuilding_sub_txt {
    text-align: center;
  }

  .first_section {
    padding-top: 7%;
  }

  .sub-heading {
    font-size: 13px;
    line-height: 20px;
  }
}
/* For Small screen Laptop Devices */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .contentHomeDesign {
    margin-bottom: 20px !important;
  }
  .cal_btn {
    width: 100% !important;
  }
  .mainHappyCustomerBox {
    padding: 20px !important;
  }

  .mainHappyCustomerBox .customerImage {
    padding-bottom: 15px;
  }
  .mainHappyCustomerBox {
    line-height: 1.2;
    font-size: 14px;
  }
  .mainHappyCustomerBox h4 {
    padding-top: 15px;
    font-size: 17px;
  }

  .first_sec_sub_txt {
    font-size: 15px;
  }

  .first_sec_image {
    height: 420px;
  }

  /* .scroll_down {
    display: none;
  } */

  .smdk_hd_txt {
    display: none;
  }

  .designBuilding h4 {
    font-size: 16px;
  }

  .btnWhite {
    padding: 14px;
    font-size: 12px;
  }

  #contentChooseTab {
    padding: 44px 35px;
  }

  .cost_cal_packages_select {
    padding: 7px;
  }

  .cal_cost_rate {
    font-size: 16px;
  }
  .span_SBUA,
  .package_names,
  .cal_cost_button {
    font-size: 12px;
  }

  .verified_sucess {
    font-size: 13px;
  }

  .first_sec_head_txt {
    font-size: 30px;
    line-height: 40px;
  }

  .customdesign_banner h3 {
    font-size: 21px;
  }

  .sub-heading {
    font-size: 15px;
    line-height: 20px;
  }
}
.number_edit_91 {
  display: flex;
  align-items: center;
  padding: 10px;
  text-align: center;
  white-space: nowrap;
  font-family: "SVM";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  background: #ffe596;
  border-radius: 1px 0px 0px 1px;
}
